<template>
  <div>
    <NavigationBar titel="Veranstaltungen" :actions="actions"></NavigationBar>
    <section>
      <TippCard hintID="SCHEDULING_EVENTS_1_V2">
        <template v-slot:content>
          <p class="body-2">
            Bevorstehende Veranstaltungen werden hier zusammen mit den
            wichtigsten Informationen angezeigt. Deine Rückmeldung kannst du
            direkt hier über den Button und in den Veranstaltungsdetails
            abgeben.
            <br /><br />
            Drei verschiedene Ansichten geben einen Überblick über die
            notwendigen Daten: Die eigene Rückmeldung kann über die
            <v-icon dense>mdi-view-agenda-outline</v-icon> komplexe Ansicht
            bereits bearbeitet werden. In der
            <v-icon dense>mdi-view-sequential-outline</v-icon> kompakten Ansicht
            werden nur wichtigen Daten zur Veranstaltung angezeigt. Die
            <v-icon dense>mdi-table</v-icon> Tabelle listet die Veranstaltungen
            mit Sortierfunktionen auf.
          </p>

          <p class="body-2">
            Folgende Icons und Farben kennzeichnen deine Rückmeldung
            übersichtlich:
          </p>
          <div>
            <v-chip
              label
              small
              color="green lighten-4"
              class="mr-1 mt-1 green--text text--darken-4"
            >
              <v-icon small>mdi-check-circle</v-icon>
              <span class="ml-1 font-weight-bold">Verfügbar</span>
            </v-chip>

            <v-chip
              label
              small
              color="orange lighten-4"
              class="mr-1 mt-1 orange--text text--darken-4"
            >
              <v-icon small>mdi-minus-circle</v-icon>
              <span class="ml-1 font-weight-bold">Wenn notwendig</span>
            </v-chip>

            <v-chip
              label
              small
              color="red lighten-4"
              class="mr-1 mt-1 red--text text--darken-4"
            >
              <v-icon small>mdi-close-circle</v-icon>
              <span class="ml-1 font-weight-bold">Nicht verfügbar</span>
            </v-chip>

            <v-chip
              label
              small
              color="blue lighten-4"
              class="mr-1 mt-1 blue--text text--darken-4"
            >
              <v-icon small>mdi-airplane</v-icon>
              <span class="ml-1 font-weight-bold">Nicht in Stadt</span>
            </v-chip>

            <v-chip
              label
              small
              color="blue-grey lighten-4"
              class="mr-1 mt-1 blue-grey--text text--darken-4"
            >
              <v-icon small>mdi-help-circle</v-icon>
              <span class="ml-1 font-weight-bold">Keine Rückmeldung</span>
            </v-chip>

            <v-chip
              label
              small
              color="purple lighten-4"
              class="mr-1 mt-1 purple--text text--darken-4"
            >
              <v-icon small>mdi-cancel</v-icon>
              <span class="ml-1 font-weight-bold"
                >Veranstaltung teilnahmebeschränkt</span
              >
            </v-chip>
          </div>
        </template>
      </TippCard>
      <TippCard
        titel="Mit Kalenderfeeds Termine synchronisieren"
        hintID="NEW_CALENDAR_FEEDS_V1"
      >
        <template v-slot:content>
          <p class="body-2">
            Egal ob iCal, Outlook oder Google Calendar: Mit einem persönlichen
            Kalenderfeed werden Veranstaltungen über einen Link direkt mit
            Deiner Kalender-App synchronisiert.
          </p>
          <v-btn
            depressed
            class="mt-2"
            :to="{ name: 'scheduling-calendar-feeds' }"
            >Persönlichen Kalenderfeed anfordern<v-icon right
              >mdi-chevron-right</v-icon
            ></v-btn
          >
        </template>
      </TippCard>
      <TippCard
        titel="🎉 Neu: Teilnahme von Veranstaltungen absagen!"
        text="Musst Du deine Teilnahme an einer Veranstaltung aus wichtigem Grund
            absagen, hast aber bereits eine positive Rückmeldung abgegeben und
            die Rückmeldefrist ist abgelaufen? Die Teilnahme lässt sich für
            diese Veranstaltungen über Mira One direkt absagen. Festgelegte
            Personen und Organisatoren der Veranstaltung werden benachrichtigt
            und die eigene Rückmeldung angepasst."
        hintID="NEW_SCHEDULING_EVENTS_CANCELLATION_NOTICE_V1"
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-calendar-account"
                title="Bevorstehende Veranstaltungen"
              >
                <template v-slot:actions>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="
                          $store.state.organization.activeOrganization.config
                            .scheduling.showAllEventsInList ||
                          checkIfSupport ||
                          checkIfItService
                        "
                        outlined
                        :color="$vuetify.theme.dark ? '' : 'white'"
                        v-bind="attrs"
                        v-on="on"
                        :class="[
                          'mr-4',
                          'hidden-sm-and-down',
                          'v-btn-toggle',
                          $vuetify.theme.dark
                            ? ''
                            : 'grey--text text--darken-1',
                        ]"
                        style="
                          border-color: rgba(0, 0, 0, 0.12) !important;
                          border-width: 1px !important;
                        "
                      >
                        <span>
                          <v-icon left small>mdi-calendar-filter</v-icon>
                          {{ eventsFilter }}
                        </span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list nav>
                      <v-list-item-group v-model="eventsFilter" mandatory>
                        <v-list-item link dense value="Alle">
                          <v-list-item-icon>
                            <v-icon dense>mdi-all-inclusive</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            >Alle Veranstaltungen anzeigen</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item link dense value="Eigene">
                          <v-list-item-icon>
                            <v-icon dense>mdi-filter</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            >Nur eigene Veranstaltungen</v-list-item-title
                          >
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-btn-toggle dense v-model="helpers.displayMode" mandatory>
                    <v-btn outlined icon value="detailed-list">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-view-agenda-outline</v-icon
                      >
                    </v-btn>
                    <v-btn outlined icon value="list">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-view-sequential-outline</v-icon
                      >
                    </v-btn>
                    <v-btn depressed outlined icon value="table">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-table</v-icon
                      >
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </Subheader>
              <div
                v-if="
                  helpers.displayMode === 'detailed-list' ||
                  helpers.displayMode === 'list'
                "
                class="mt-7"
              >
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
                ></v-skeleton-loader>
                <v-data-iterator
                  v-else
                  :items="
                    schedulingConfigShowAllEventsInList &&
                    eventsFilter === 'Alle'
                      ? allEvents
                      : onlyOwnEvents
                  "
                  item-key="meta.id"
                  sort-by="duration.start.firebaseFormat"
                  :sort-desc="false"
                  :footer-props="footerProps"
                  :items-per-page="15"
                  no-data-text="Keine Veranstaltungen vorhanden"
                >
                  <template v-slot:header>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            $store.state.organization.activeOrganization.config
                              .scheduling.showAllEventsInList ||
                            checkIfSupport ||
                            checkIfItService
                          "
                          :color="$vuetify.theme.dark ? '' : 'white'"
                          v-bind="attrs"
                          v-on="on"
                          class="mb-4 mt-n2 hidden-md-and-up"
                          block
                          depressed
                          elevation="1"
                        >
                          <span>
                            <v-icon left small>mdi-calendar-filter</v-icon>
                            {{ eventsFilter }}
                          </span>
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list nav>
                        <v-list-item-group v-model="eventsFilter" mandatory>
                          <v-list-item link dense value="Alle">
                            <v-list-item-icon>
                              <v-icon dense>mdi-all-inclusive</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              >Alle Veranstaltungen anzeigen</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item link dense value="Eigene">
                            <v-list-item-icon>
                              <v-icon dense>mdi-filter</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              >Nur eigene Veranstaltungen</v-list-item-title
                            >
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </template>
                  <template v-slot:default="props">
                    <v-card>
                      <v-list>
                        <div v-for="event in props.items" :key="event.meta.id">
                          <v-list-item
                            v-if="
                              event &&
                              event.userResponse &&
                              event.userResponse.status
                            "
                            link
                            :to="{
                              name: 'scheduling-event-details-tab-general',
                              params: { itemId: event.meta.id },
                            }"
                          >
                            <div
                              v-if="
                                checkPermission(manageOwnResponsePermission) &&
                                event.userResponse &&
                                event.userResponse.status
                              "
                              class="statusDisplay"
                              v-bind:style="
                                createStatusStyle(event.userResponse.status.id)
                              "
                            ></div>
                            <v-list-item-content>
                              <v-list-item-title
                                class="subtitle-1 font-weight-medium mb-1"
                              >
                                <v-icon
                                  v-if="
                                    $store.state.user.user.settings
                                      .accessability.useColorblindMode
                                  "
                                  small
                                  :color="
                                    statusColorAsHex(
                                      event.userResponse.status.id
                                    )
                                  "
                                  class="mr-2"
                                >
                                  {{ statusIcon(event.userResponse.status.id) }}
                                </v-icon>
                                <span>{{ event.title }}</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <p style="line-height: 1.6" class="mb-0">
                                  <v-icon small left>mdi-calendar-badge</v-icon
                                  >{{ event.type.title }}
                                  <br />
                                  <v-icon small left>mdi-calendar-clock</v-icon
                                  >{{ event.duration.start.timestamp }} bis
                                  {{
                                    tryToDisplayOnlyEndTime(
                                      event.duration.start.firebaseFormat,
                                      event.duration.end.firebaseFormat,
                                      event.duration.end.timestamp
                                    )
                                  }}<br />
                                  <v-icon v-if="event.location.name" small left
                                    >mdi-map-marker</v-icon
                                  >{{ event.location.name }}<br />
                                </p>
                                <v-chip
                                  v-if="event.config.attendance.isMandatory"
                                  x-small
                                  class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
                                  color="red lighten-4"
                                  ><v-icon x-small left>mdi-alert-circle</v-icon
                                  >Anwesenheitspflicht</v-chip
                                >
                                <v-chip
                                  v-if="showResponsesLocked(event)"
                                  x-small
                                  depressed
                                  class="mt-2 mr-2 font-weight-medium"
                                  ><v-icon x-small left>mdi-lock</v-icon
                                  >Rückmeldungen gesperrt</v-chip
                                >
                                <v-chip
                                  v-if="showResponsesAreBinding(event)"
                                  x-small
                                  depressed
                                  class="mt-2 mr-2 font-weight-medium"
                                  ><v-icon x-small left
                                    >mdi-check-decagram</v-icon
                                  >Rückmeldung verbindlich</v-chip
                                >
                                <v-chip
                                  v-if="
                                    event.config.responses.deadline.isCustom
                                  "
                                  x-small
                                  depressed
                                  class="mt-2 mr-2 font-weight-medium"
                                  ><v-icon x-small left>mdi-clock-alert</v-icon
                                  >Veränderte Rückmeldefrist</v-chip
                                >
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action-text
                              v-if="helpers.displayMode === 'detailed-list'"
                              class="hidden-sm-and-down"
                            >
                              <v-card v-if="checkFeatureAvability" outlined>
                                <v-card-text class="my-n3">
                                  <div>Abgegebene Rückmeldungen (Beta)</div>
                                  <response-statistics-card
                                    small
                                    marginClass="my-1"
                                    :totalConfirmations="
                                      event.count.totalConfirmations
                                    "
                                    :totalIfNecessary="
                                      event.count.totalIfNecessary
                                    "
                                    :totalCancellations="
                                      event.count.totalCancellations +
                                      event.count.totalAway
                                    "
                                    :totalResponses="event.count.totalResponses"
                                  ></response-statistics-card> </v-card-text
                              ></v-card>
                            </v-list-item-action-text>
                            <v-list-item-action
                              v-if="helpers.displayMode === 'list'"
                            >
                              <v-btn
                                color="red darken-1"
                                icon
                                :to="{
                                  name: 'scheduling-event-details-tab-general',
                                  params: { itemId: event.meta.id },
                                }"
                              >
                                <v-icon large> mdi-chevron-right </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-card
                            v-if="
                              helpers.displayMode === 'detailed-list' &&
                              checkFeatureAvability &&
                              event &&
                              event.userResponse &&
                              event.userResponse.status
                            "
                            outlined
                            class="mx-3 mb-2 hidden-md-and-up"
                          >
                            <v-card-text class="my-n3">
                              <div>Abgegebene Rückmeldungen (Beta)</div>
                              <response-statistics-card
                                small
                                :totalConfirmations="
                                  event.count.totalConfirmations
                                "
                                :totalIfNecessary="event.count.totalIfNecessary"
                                :totalCancellations="
                                  event.count.totalCancellations +
                                  event.count.totalAway
                                "
                                :totalResponses="event.count.totalResponses"
                              ></response-statistics-card>
                            </v-card-text>
                          </v-card>
                          <v-card
                            v-if="
                              helpers.displayMode === 'detailed-list' &&
                              checkPermission(manageOwnResponsePermission) &&
                              (showEditResponseBtn(event) ||
                                showCancelResponseBtn(event))
                            "
                            flat
                            tile
                          >
                            <v-chip
                              v-if="event.userResponse.isAccepted"
                              label
                              small
                              class="font-weight-medium ml-5 mr-2 mb-2 orange--text text--darken-4"
                              color="orange lighten-4"
                              ><v-icon left small>mdi-star</v-icon
                              >Eingeteilt</v-chip
                            >
                            <v-chip
                              v-if="event.userResponse.assignedPosition.comboId"
                              label
                              small
                              class="font-weight-medium mb-2 mr-2"
                              ><v-icon left small>mdi-fire-truck</v-icon
                              >{{
                                event.userResponse.assignedPosition.unit.title
                              }}
                              –
                              {{
                                event.userResponse.assignedPosition.position
                                  .shortTitle
                              }}</v-chip
                            >
                            <v-chip
                              v-if="
                                event.userResponse.assignedPosition.custom.text
                              "
                              label
                              small
                              class="font-weight-medium mr-2 mb-2"
                              >{{
                                event.userResponse.assignedPosition.custom.text
                              }}</v-chip
                            >
                            <event-response-quick-editor
                              v-if="
                                checkPermission(manageOwnResponsePermission) &&
                                showEditResponseBtn(event)
                              "
                              :event="event"
                              class="mx-3 mb-1"
                            ></event-response-quick-editor>
                            <v-card-actions
                              v-if="showCancelResponseBtn(event)"
                              class="pt-1 mx-3"
                            >
                              <v-btn
                                v-if="showCancelResponseBtn(event)"
                                small
                                depressed
                                color="error"
                                :to="{
                                  name: 'scheduling-event-details-tab-general',
                                  params: { itemId: event.meta.id },
                                }"
                                ><v-icon small left>mdi-run-fast</v-icon
                                >Teilnahme absagen</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                          <v-divider></v-divider>
                        </div>
                      </v-list>
                    </v-card>
                  </template>
                </v-data-iterator>
              </div>
              <div v-else class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="table"
                ></v-skeleton-loader>
                <v-data-table
                  v-else
                  :headers="computedHeaders"
                  :items="
                    schedulingConfigShowAllEventsInList &&
                    eventsFilter === 'Alle'
                      ? allEvents
                      : onlyOwnEvents
                  "
                  item-key="meta.id"
                  multi-sort
                  sort-by="duration.start.firebaseFormat"
                  :sort-desc="false"
                  :footer-props="footerProps"
                  :items-per-page="25"
                  class="elevation-2"
                  max-height="800px auto"
                  no-data-text="Keine Veranstaltungen vorhanden"
                  @click:row="openItem"
                >
                  <template v-slot:[`item.userResponse`]="{ item }">
                    <v-avatar
                      :color="statusColorAsHex(item.userResponse.status.id)"
                      size="24"
                      rounded
                    >
                      <v-icon small dark>
                        {{ statusIcon(item.userResponse.status.id) }}
                      </v-icon>
                    </v-avatar>
                  </template>
                  <template
                    v-slot:[`item.duration.start.firebaseFormat`]="{ item }"
                  >
                    {{ item.duration.start.timestamp }}
                  </template>
                  <template v-slot:[`item.responses`]="{ item }">
                    <response-statistics-card
                      v-if="checkFeatureAvability"
                      small
                      marginClass="my-1"
                      :totalConfirmations="item.count.totalConfirmations"
                      :totalIfNecessary="item.count.totalIfNecessary"
                      :totalCancellations="
                        item.count.totalCancellations + item.count.totalAway
                      "
                      :totalResponses="item.count.totalResponses"
                    ></response-statistics-card>
                  </template>
                  <template v-slot:no-data>
                    <div
                      style="
                        height: 300px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <p class="subtitle-1">Keine Veranstaltungen vorhanden.</p>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import { SCHEDULING } from "@/store/modules.js";
import {
  GET_EVENTS,
  // CREATE_EVENT_CANCELLATION_NOTICE,
} from "@/store/action-types.js";
import {
  SCHEDULING_EVENTS_RESPONSES_OWN_update,
  SCHEDULING_EVENTS_create,
} from "@/data/permission-types.js";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import ResponseStatisticsCard from "@/components/scheduling/ResponseStatisticsCard.vue";
import EventResponseQuickEditor from "@/components/scheduling/EventResponseQuickEditor.vue";

export default {
  name: "scheduling-events",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
    EventResponseQuickEditor,
    ResponseStatisticsCard,
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },
      sortDesc: true,
      sortBy: "title",
      eventsFilter: "Eigene",
      manageOwnResponsePermission: `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`,

      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnMultiple",
          permission: `${SCHEDULING_EVENTS_create}`,
          list: [
            {
              title: "Veranstaltung erstellen",
              icon: "mdi-plus-circle",
              function: () => {
                this.$router.push({ name: "scheduling-event-new" });
              },
            },
            {
              title: "Veranstaltung mit Vorlage erstellen",
              icon: "mdi-book-arrow-right",
              function: () => {
                this.$router.push({
                  name: "scheduling-event-new",
                  query: { fromTemplate: true },
                });
              },
            },
            {
              title: "Mehrere Veranstaltungen erstellen",
              icon: "mdi-plus-circle-multiple",
              function: () => {
                this.$router.push({ name: "scheduling-event-new-multi" });
              },
            },
            {
              title: "Veranstaltungen mittels CSV-Import erstellen",
              icon: "mdi-database-import",
              disabled: true,
              // function: this.createFromCSV,
            },
          ],
        },
      ],
      helpers: {
        dataIsLoading: true,
        displayMode: "detailed-list",
        formIsValid: false,
        isLoading: false,
      },
      headers: [
        {
          text: "Mein Status",
          sortable: false,
          value: "userResponse",
          permissions: [`${SCHEDULING_EVENTS_RESPONSES_OWN_update}`],
          width: "1%",
        },
        { text: "Veranstaltungstitel", value: "title" },
        { text: "Veranstaltungsart", value: "type.title" },
        { text: "Startzeitpunkt", value: "duration.start.firebaseFormat" },
        {
          text: "Endzeitpunkt",
          value: "duration.end.timestamp",
          sortable: false,
        },
        { text: "Ort", value: "location.name" },
      ],
    };
  },
  computed: {
    code() {
      return [
        { title: "events", data: this.events },
        { title: "eventsStatus", data: this.eventsStatus },
        { title: "allEvents", data: this.allEvents },
        { title: "schedulingConfig", data: this.schedulingConfig },
      ];
    },
    computedHeaders() {
      let headers = this.headers.filter(
        (p) => !p.permissions || !this.checkMultiplePermissions(p.permissions)
      );

      if (this.checkFeatureAvability) {
        headers.push({
          text: "Rückmeldungen (Beta)",
          value: "responses",
        });
      }
      return headers;
    },
    checkFeatureAvability() {
      return this.$store.getters["organization/checkFeatureAvailabilityById"](
        "showEventResponsesCount"
      );
    },
    organizationAllowsCancellationNotice() {
      return this.$store.state.organization.activeOrganization.config.scheduling
        .cancellation.allowNotice;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    checkIfItService() {
      return this.$store.getters["organization/checkIfItService"];
    },
    schedulingConfig() {
      return this.$store.state.organization.activeOrganization.config
        .scheduling;
    },
    schedulingConfigShowAllEventsInList() {
      return this.$store.state.organization.activeOrganization.config.scheduling
        .showAllEventsInList;
    },
    events() {
      return this.$store.state.scheduling.events;
    },
    eventsStatus() {
      return this.$store.state.scheduling.eventsStatus;
    },
    allEvents() {
      return this.events.map((event) => {
        const userResponse = this.eventsStatus.find(
          (userResponse) => userResponse.userResponse.eventId === event.meta.id
        );

        if (
          userResponse &&
          userResponse.userResponse.status.id === "0" &&
          !this.userIsInvited(event)
        ) {
          userResponse.userResponse.status.id = "-0";
        }

        return {
          ...event,
          ...userResponse,
          deadlineIsExpired: this.deadlineIsExpired(event),
        };
      });
    },
    onlyOwnEvents() {
      const userGroupId = this.$store.state.organization.membership.group.id;
      return this.allEvents.filter((event) => {
        const { allowedAttendeesIds, isLimited } = event.config.attendance;
        return (
          !isLimited ||
          allowedAttendeesIds.includes("all") ||
          allowedAttendeesIds.includes(auth.currentUser.uid) ||
          allowedAttendeesIds.includes(userGroupId)
        );
      });
    },
  },
  created() {
    this.initialize();
    if (this.events.length > 0) {
      this.helpers.dataIsLoading = false;
    }
  },
  methods: {
    initialize() {
      return this.getEvents().then(
        () => {
          this.helpers.dataIsLoading = false;
        },
        (error) => {
          console.error(error);
        }
      );
    },
    getEvents() {
      return this.$store.dispatch(`${SCHEDULING}${GET_EVENTS}`, {
        organizationId: this.$route.params.organizationId,
        withStatus: true,
        query: {
          onlyOwn: false,
        },
      });
    },
    userIsInvited(event) {
      if (!event.config.attendance.isLimited) {
        return true;
      }

      const userGroupId = this.$store.state.organization.membership.group.id;
      const allowedAttendees = event.config.attendance.allowedAttendeesIds;

      return (
        allowedAttendees.includes("all") ||
        allowedAttendees.includes(auth.currentUser.uid) ||
        allowedAttendees.includes(userGroupId)
      );
    },
    tryToDisplayOnlyEndTime(startTimestamp, endTimestamp, endTimestampString) {
      const startDate = startTimestamp.toDate();
      const endDate = endTimestamp.toDate();

      if (
        startDate.getDate() === endDate.getDate() &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear()
      ) {
        return endDate.toLocaleTimeString("de-DE", {
          hour: "2-digit",
          minute: "2-digit",
        });
      }

      return endTimestampString;
    },
    deadlineIsExpired(event) {
      const deadline = this.getDeadline(event, false);
      const now = new Date();

      return now.getTime() > deadline.getTime();
    },
    getDeadline(event, withFormat) {
      const deadline = event.config.responses.deadline;
      if (deadline.isCustom) {
        // custom deadline
        if (withFormat) {
          return this.timestampConverter(deadline.timestamp);
        } else {
          return deadline.timestamp.toDate();
        }
      } else {
        // global deadline

        var durationInMin = this.schedulingConfig.defaultDeadlineInMinutes;
        //var durationInMin = 60;
        var MS_PER_MINUTE = 60000;
        var enddate = event.duration.start.firebaseFormat.toDate();
        var calc = new Date(enddate - durationInMin * MS_PER_MINUTE);

        // V2: also working
        // var startdate = new Date(enddate);
        // const output = new Date(startdate.setUTCMinutes(enddate.getUTCMinutes() - durationInMin));

        if (withFormat) {
          // return this.timestampConverter(calc);
          const options = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          };
          return calc.toLocaleDateString("de-DE", options);
        } else {
          return calc;
        }
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
    checkMultiplePermissions(items) {
      if (items && items.length > 0) {
        return false;
      } else {
        //return true;
        return this.$store.getters["organisation/checkPermissionByMultipleIds"](
          items
        );
      }
    },
    openItem(item) {
      this.$router.push({
        name: "scheduling-event-details-tab-general",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    statusIcon(item) {
      switch (item) {
        case "-0":
          return "mdi-cancel";
        case "0":
          return "mdi-help";
        case "1":
          return "mdi-check";
        case "2":
          return "mdi-close";
        case "3":
          return "mdi-minus";
        case "4":
          return "mdi-airplane";
        case "":
          return "mdi-alert-outline";
        default:
          return "";
      }
    },
    statusColor(item) {
      switch (item) {
        case "-0":
          return "purple darken-2";
        case "0":
          return "blue-grey darken-2";
        case "1":
          return "success";
        case "2":
          return "error";
        case "3":
          return "warning";
        case "4":
          return "info";
        case "":
          return "blue-grey darken-2";
        default:
          return "";
      }
    },
    statusColorAsHex(item) {
      switch (item) {
        case "-0":
          return "#7B1FA2";
        case "0":
          return "#455a64";
        case "1":
          return "#4caf50";
        case "2":
          return "#ff5252";
        case "3":
          return "#fb8c00";
        case "4":
          return "#2196f3";
        case "":
          return "#455a64";
        default:
          return "";
      }
    },
    createStatusStyle(status) {
      if (!status) {
        console.error("Status is undefined");
        return "";
      }
      return `background-color: ${this.statusColorAsHex(status)}`;
    },
    showResponsesAreBinding(event) {
      if (!event) {
        console.error("Event is undefined - showResponsesAreBinding");
        return false;
      }
      return this.userIsInvited(event) && event.config.responses.areBinding;
    },
    showEditResponseBtn(event) {
      if (!event || !event.userResponse || !event.userResponse.status) {
        console.error("Event is undefined - showEditResponseBtn");
        return false;
      }

      const userResponseStatusIsZero = event.userResponse.status.id == "0";
      const responsesAreBinding = event.config.responses.areBinding;
      return (
        this.userIsInvited(event) &&
        !event.config.responses.areLocked &&
        !event.deadlineIsExpired &&
        ((userResponseStatusIsZero && responsesAreBinding) ||
          !responsesAreBinding)
      );
    },
    showCancelResponseBtn(event) {
      if (!event || !event.userResponse || !event.userResponse.status) {
        console.error("Event is undefined - showCancelResponseBtn");
        return false;
      }
      const eventHasStarted =
        new Date().getTime() > event.duration.start.timestampToDate;
      const userResponseStatus =
        event.userResponse.status.id === "1" ||
        event.userResponse.status.id === "3";
      return (
        this.organizationAllowsCancellationNotice &&
        this.userIsInvited(event) &&
        !eventHasStarted &&
        !event.userResponse.isCanceled &&
        (event.deadlineIsExpired || event.config.responses.areBinding) &&
        userResponseStatus
      );
    },
    showResponsesLocked(event) {
      if (!event) {
        console.error("Event is undefined - showResponsesLocked");
        return false;
      }
      return (
        this.userIsInvited(event) &&
        event.config.responses.areLocked &&
        !event.deadlineIsExpired
      );
    },
  },
};
</script>

<style>
.statusDisplay {
  height: 80px;
  width: 8px;
  border-radius: 0px 40px 40px 0px;
  margin-left: -16px;
  margin-right: 10px;
}
</style>
