<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <div class="mt-3">
                <v-row class="match-height">
                  <v-col cols="12" sm="12" md="6" v-if="checkIfSupport">
                    <v-card>
                      <v-card-subtitle
                        ><v-icon x-small class="mr-2">mdi-tools</v-icon
                        >Rückmeldungen in Datensatz
                      </v-card-subtitle>
                      <v-card-text>
                        <response-statistics-card
                          marginClass="mr-2"
                          :totalConfirmations="event.count.totalConfirmations"
                          :totalIfNecessary="event.count.totalIfNecessary"
                          :totalCancellations="event.count.totalCancellations"
                          :totalNotInTown="event.count.totalAway"
                          :totalResponses="event.count.totalResponses"
                        ></response-statistics-card>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    v-else
                    cols="12"
                    sm="12"
                    md="6"
                    class="hidden-sm-and-down"
                  >
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="article"
                    ></v-skeleton-loader>
                    <v-card v-else>
                      <v-card-subtitle
                        >Zusammenfassung der Rückmeldungen</v-card-subtitle
                      >
                      <v-card-text>
                        <response-statistics-card
                          marginClass="mr-2 mb-2"
                          :totalConfirmations="getStatusCount('1')"
                          :totalIfNecessary="getStatusCount('3')"
                          :totalCancellations="getStatusCount('2')"
                          :totalNotInTown="getStatusCount('4')"
                          :totalAwaitingResponse="getStatusCount('0')"
                          :totalResponses="getStatusCount('all')"
                        ></response-statistics-card>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-skeleton-loader
                      v-if="helpers.dataIsLoading"
                      elevation="2"
                      type="table-heading, table-thead, table-tbody"
                    ></v-skeleton-loader>
                    <v-card v-else>
                      <event-responses-data-table
                        :eventResponses="eventResponsesMatched"
                        mode="view"
                      ></event-responses-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      ><v-icon color="success" class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "@/firebase";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import ResponseStatisticsCard from "@/components/scheduling/ResponseStatisticsCard.vue";
import EventResponsesDataTable from "@/components/scheduling/EventResponsesDataTable.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_LIST } from "@/store/action-types.js";

export default {
  name: "scheduling-event-details-tab-responses",
  components: {
    SupportTools,
    EventResponsesDataTable,
    ResponseStatisticsCard,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      data: { meta: { status: "" } },

      helpers: {
        dataIsLoading: true,

        snackbar: {
          isVisible: false,
          message: "",
        },
      },

      eventResponses: [],
      status: ["1", "3", "2", "4", "0"],
      userResponse: {
        user: {
          uid: null,
        },
        status: { id: null },
        comment: null,
        type: "regular",
        assignedTasks: [],
        assignedPosition: {
          custom: {
            text: "",
          },
          unit: {
            id: "",
            title: "",
          },
          position: {
            id: "",
            shortTitle: "",
          },
          comboId: "",
        },
        isCanceled: false,
        guests: { count: 0 },
      },
    };
  },
  computed: {
    code() {
      return [
        { title: "event", data: this.event },
        { title: "eventResponses", data: this.eventResponses },
        { title: "memberships", data: this.memberships },
        { title: "membershipsPrepared", data: this.membershipsPrepared },
        { title: "eventResponsesMatched", data: this.eventResponsesMatched },
        {
          title: "countDifference",
          data: [this.memberships.length, this.membershipsPrepared.length],
        },
        {
          title: "membershipsPreparedDisplayName",
          data: this.membershipsPrepared.map((a) => a.user.displayName),
        },
      ];
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    membershipsPrepared() {
      var range = {
        start: this.event.duration.start.timestampToDate,
        end: this.event.duration.end.timestampToDate,
      };
      var membershipsFiltered = this.memberships.filter((obj) => {
        return (
          new Date(obj.user.memberSinceToDate) <= new Date(range.start) && // All members that where members before or at the start of this event
          !(new Date(obj.user.memberUntilToDate) <= new Date(range.end)) && // All members that didn't leave before or at the event of this event
          obj.access.type === "member"
        );
      });

      var memberships = membershipsFiltered;
      // if (this.event.status !== "planned") {}
      const result = memberships.map((item) => {
        const container = {
          groupByGroup:
            item.group.sortKey.toString().padStart(4, "0") +
            "_" +
            item.group.title,
          group: {
            title: item.group.title,
            sortKey: item.group.sortKey,
            id: item.group.id,
          },
          user: {
            displayName: item.user.displayName,
            uid: item.user.uid,
            // photoURL = item.user.photoURL,
            // position = item.user.dutyPosition,
          },
        };
        return container;
      });
      return result;
    },

    eventResponsesMatched() {
      const memberships = this.membershipsPrepared;
      // 1: Match memberships to event status
      // const matched = this.eventResponses.map((response) => {
      //   const matchedMembership = memberships.find(
      //     (membership) => membership.user.uid === response.meta.uid
      //   );
      //   return { ...response, ...matchedMembership };
      // });
      const matched = this.eventResponses
        .map((response) => {
          const matchedMembership = memberships.find(
            (membership) => membership.user.uid === response.meta.uid
          );
          // Check if matchedMembership is not undefined before merging
          if (matchedMembership) {
            return { ...response, ...matchedMembership };
          }
          // If matchedMembership is undefined, return null
          return null;
        })
        // Filter out any null values from the array
        .filter((item) => item !== null);

      // 2: Find missing memberships
      const missing = memberships.filter((o) =>
        matched.every((s) => s.user.uid !== o.user.uid)
      );

      // 3: Create event status for missing memberships
      var addedStatus = [];
      if (missing.length > 0) {
        addedStatus = missing.map((obj) => ({
          ...obj,
          comment: "",
          type: "regular",
          isCanceled: false,
          meta: {
            uid: obj.user.uid,
            lastUpdatedBy: "",
          },
          groupByStatus: "5_Keine Rückmeldung",
          status: {
            id: "0",
          },
          assignedTasks: [],
          assignedPosition: {
            custom: {
              text: "",
            },
            unit: {
              id: "",
              title: "",
            },
            position: {
              id: "",
              shortTitle: "",
            },
            comboId: "",
          },
          guests: {
            count: 0,
          },
        }));
      }

      // 4: Combine both groups as output
      let output = matched.concat(addedStatus);

      // 5: Delete items with user.uid or group.id not in allowedAttendeesIds, excluding items with status.id !== "0"
      if (this.event.config.attendance.isLimited) {
        const allowedAttendeesIds =
          this.event.config.attendance.allowedAttendeesIds;
        const outputFiltered = output.filter((obj) => {
          return (
            allowedAttendeesIds.includes(obj.user.uid) ||
            allowedAttendeesIds.includes(obj.group.id) ||
            obj.status.id !== "0"
          );
        });

        return outputFiltered;
      }
      return output;
    },

    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
    event: function (newValue) {
      this.data = newValue;
      if (newValue !== null) {
        this.getMemberships();
      }
    },
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(this.$route.params.itemId)
        .collection("responses")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              ...doc.data(),
              groupByStatus:
                this.orderPrefix(doc.data().status.id) +
                "_" +
                this.statusText(doc.data().status.id),
              status: {
                id: doc.data().status.id,
              },
              meta: {
                uid: doc.data().meta.uid,
                lastUpdatedAt: doc.data().meta.lastUpdatedAt,
                lastUpdatedBy: doc.data().meta.lastUpdatedBy,
              },
              // assignedPosition: {
              //   custom: {
              //     text: "",
              //   },
              //   unit: {
              //     id: "",
              //     title: "",
              //   },
              //   position: {
              //     id: "",
              //     shortTitle: "",
              //   },
              //   comboId: "",
              // },
            };
            if (
              data.meta.lastUpdatedBy &&
              data.meta.lastUpdatedBy !== data.meta.uid
            ) {
              data.meta.lastUpdatedByDisplayName = this.getDisplayName(
                data.meta.lastUpdatedBy
              );
            }
            this.eventResponses.push(data);
          });
        })
        .then(() => {
          this.helpers.dataIsLoading = false;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },

    getMemberships() {
      if (this.data.status === "planned") {
        this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "active",
          },
        });
      } else {
        this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: false,
          query: {
            type: "archived",
            memberUntil: this.convertToDate(
              this.event.duration.start.timestamp
            ),
          },
        });
      }
    },
    getDisplayName(uid) {
      const user = this.memberships.find(
        (membership) => membership.user.uid === uid
      );
      return user.user.displayName || "";
    },

    statusText(item) {
      switch (item) {
        case "-1":
          return "Nicht eingeladen";
        case "0":
          return "Keine Rückmeldung";
        case "1":
          return "Verfügbar";
        case "2":
          return "Nicht verfügbar";
        case "3":
          return "Wenn notwendig";
        case "4":
          return "Nicht in Stadt";
        case "":
          return "Fehler";
        default:
          return "";
      }
    },
    orderPrefix(item) {
      switch (item) {
        case "-1":
          return "7";
        case "0":
          return "5";
        case "1":
          return "1";
        case "3":
          return "2";
        case "2":
          return "3";
        case "4":
          return "4";
        case "X":
          return "X";
        default:
          return "";
      }
    },

    getStatusCount(statusId) {
      if (statusId == "all") {
        return (
          this.countStatusById("1") +
          this.countStatusById("2") +
          this.countStatusById("3") +
          this.countStatusById("4")
        );
      } else {
        return this.countStatusById(statusId);
      }
    },
    countStatusById(statusId) {
      var count = this.eventResponsesMatched.reduce(function (n, userResponse) {
        return n + (userResponse.status.id == statusId);
      }, 0);
      return count;
    },

    convertToDate(date) {
      date = date.toDate();
      return (
        (date.getDate() < 10 ? "0" : "") +
        date.getDate() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        date.getFullYear()
      );
    },
  },
};
</script>
