<template>
  <div>
    <template>
      <v-row>
        <v-col cols="12">
          <v-select
            v-if="mode !== 'own'"
            v-model="response.user.uid"
            label="Person auswählen"
            :items="memberships"
            item-text="user.displayName"
            item-value="user.uid"
            disable-lookup
            :disabled="mode === 'edit'"
            single-line
            outlined
          ></v-select>
          <v-textarea
            v-model.trim="cancellationNotice.notification.message"
            clearable
            no-resize
            label="Nachricht"
            persistent-hint
            hint="Diese Nachricht wird per E-Mail an die Organisatoren der Veranstaltung sowie festgelegte Personen gesendet."
            :disabled="inputDisabled"
            outlined
            rows="3"
          ></v-textarea>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "response-cancellation-notice-editor",
  props: {
    cancellationNotice: {},
    mode: {
      type: String,
      default: "own",
      required: true,
      validator: function (value) {
        return ["own", "new"].indexOf(value) !== -1;
      },
    },
    memberships: {
      type: Array,
      required: false,
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "cancellationNotice",
    event: "cancellationNoticeChange",
  },
  created() {},
  computed: {
    cancellationNoticeLocal: {
      get: function () {
        return this.cancellationNotice;
      },
      set: function (value) {
        this.$emit("cancellationNotice", value);
      },
    },
  },
};
</script>
